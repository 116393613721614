import React from 'react'
import { useTranslate } from '../src/hooks/useTranslate'
import { ImageFragment, ProductUnit } from '../src/model/fragments'
import { Picture, PictureFit } from './Picture'

interface ProductVariantProps {
	name?: string
	description?: string
	image?: ImageFragment
	prices?: string[],
	unit?: ProductUnit,
	wide?: boolean,
	allPossibleNames?: string[]
	allPossibleDescriptions?: string[]
}

export const ProductVariant: React.FunctionComponent<ProductVariantProps> = ({
	name,
	description,
	image,
	prices,
	unit,
	wide = false,
	allPossibleNames = [],
	allPossibleDescriptions = [],
}) => {
	const translate = useTranslate();

	return (
		<div className="productVariant">
			<div className="productVariant-icon">
				{image && (
					<Picture
						alt="vzhled varianty produktu"
						image={image}
						width={wide ? 64 : 35}
						height={35}
						mode={'cover'}
					/>
				)}
			</div>
			<div className="productVariant-info">
				<div className="productVariant-name">
					{name ?? translate('default-variant')}
					{allPossibleNames.map((possibleName, i) => (
						<div
							data-content={possibleName}
							className="productVariant-name-possibility"
							key={i}
						/>
					))}
				</div>
				<div className="productVariant-description">
					{description}
					{allPossibleDescriptions.map((possibleDescription, i) => (
						<div
							data-content={possibleDescription}
							className="productVariant-description-possibility"
							key={i}
						/>
					))}
				</div>
			</div>
			{prices && <div className="productVariant-price">{prices?.join(', ')} {unit && <>/ { translate(`unit-${unit}`) }</>}</div>}
		</div>
	)
}
