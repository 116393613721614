import * as React from 'react'

function SvgChevron(props: React.SVGProps<SVGSVGElement>) {
	return (
		<svg width="0.5625em" height="0.875em" viewBox="0 0 9 14" {...props}>
			<path
				fill="currentColor"
				d="M0 12.355L5.35 7 0 1.645 1.647 0l7.008 7-7.008 7L0 12.355"
			/>
		</svg>
	)
}

export default SvgChevron
