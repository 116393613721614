import React from 'react'
import { useLocalization } from '../src/hooks/useLocaleFormatter'
import { ProductPriceFragment } from '../src/model/fragments'
import { useAdjustVariantInShoppingList } from '../src/store/shoppingListState'
import { multiplyPrice } from '../src/utils/formatPrice'
import { useIsVatPreferred } from '../src/utils/useIsVatPreferred'
import { Button, ButtonProps } from './Button'

export interface AddToShoppingListButtonProps
	extends Pick<ButtonProps, 'block'> {
	variantId: string | undefined
	variantPrice?: ProductPriceFragment | undefined
	onClick: () => void
	onMouseEnter?: () => void
	onMouseLeave?: () => void
	quantityInput: string
	customText?: React.JSX.Element | string
	customAltText?: string
}

export const AddToShoppingListButton: React.FunctionComponent<AddToShoppingListButtonProps> =
	({ variantId, variantPrice, quantityInput, onClick, onMouseEnter, onMouseLeave, block, customText, customAltText }) => {
		const localization = useLocalization()
		const adjustVariantInShoppingList = useAdjustVariantInShoppingList()

		const quantity = parseFloat(quantityInput) || 0

		const [isVatPreferred] = useIsVatPreferred()
		const isVariantChosen = variantId !== undefined
		;
		const [isQuantityFilled, setIsQuantityFilled] = React.useState<boolean>((parseFloat(quantityInput) || 0) > 0);
		React.useEffect(() => {
			setIsQuantityFilled((parseFloat(quantityInput) || 0) > 0);
		}, [quantityInput]);

		const [addToListEnabled, setAddToListEnabled] = React.useState<boolean>(isQuantityFilled && (variantId !== undefined));
		React.useEffect(() => {
			setAddToListEnabled(isQuantityFilled && (variantId !== undefined));
		}, [isQuantityFilled, variantId]);

		return (
			<Button
				disabled={
					addToListEnabled
						? false
						: !isVariantChosen
						? localization.translate('buy-button-missing-variant')
						: !isQuantityFilled
						? localization.translate('buy-button-missing-quantity')
						: true
				}
				altText={customAltText}
				block={block}
				onClick={() => {
					if (variantId !== undefined) {
						adjustVariantInShoppingList(variantId, quantity)
					}
					onClick()
				}}

				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				{ customText ?? localization.translate('buy-button-label-general')}
				{addToListEnabled && variantPrice && !customText && (
					<>
						<br />
						od {localization.formatPrice(multiplyPrice(variantPrice, quantity), 1, isVatPreferred, false)}
					</>
				)}
			</Button>
		)
	}
