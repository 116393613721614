import React from 'react'
import { useStorageBackedState } from 'use-storage-backed-state'
import { initialCounter, useGetNextCounter } from '../hooks/useGetNextCounter'

export function useQuantityInput(productId: string) {
	const getNextCounter = useGetNextCounter()
	const [localQuantity, setLocalQuantity] = useStorageBackedState(
		{
			value: '',
			counter: initialCounter,
		},
		`quantity-input-${productId}`,
		'sessionStorage' in globalThis ? sessionStorage : undefined,
	)

	const setQuantity = React.useCallback(
		(newQuantity: string) => {
			setLocalQuantity({ value: newQuantity, counter: getNextCounter() })
		},
		[setLocalQuantity, getNextCounter],
	)

	return [localQuantity, setQuantity] as const
}
