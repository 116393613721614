import { useMemo } from 'react'
import { ProductVariantFragment } from '../model/fragments'

export const useCheapestVariant = (variants: ProductVariantFragment[]) =>
	useMemo(
		() =>
			variants.reduce(
				(cheapest, variant) =>
					Math.min(...cheapest.prices.map(p => p.cents)) <= Math.min(...variant.prices.map(p => p.cents)) ? cheapest : variant,
				variants[0],
			),
		[variants],
	)
