import cn from 'classnames'
import React from 'react'
import { initialCounter } from '../src/hooks/useGetNextCounter'
import { computeProductCount, roundProductQuantity } from '../src/model/product'
import { Input, InputProps } from './Input'

export interface QuantityInputProps extends Pick<InputProps, 'micro'> {
	preferredUnits?: boolean
	quantityPerLayer?: number
	palletLayerSqm?: number
	quantityPerSqm?: number
	highlight?: boolean
	forcedQuantitySquareMeters?: {
		value: number
		counter: number
	}
	value: {
		value: string
		counter: number
	}
	setValue?: (value: string) => void
	onBlur?: (value: number) => void
}

export const QuantityInput: React.FunctionComponent<QuantityInputProps> = ({
	micro,
	onBlur,
	value,
	quantityPerLayer = 0,
	palletLayerSqm = 0,
	highlight = false,
	forcedQuantitySquareMeters = {
		value: 0,
		counter: initialCounter,
	},
	quantityPerSqm = 0,
	setValue,
	...props
}) => {
	const notEnoughDataToHandleSqm =
		quantityPerLayer === 0 || palletLayerSqm === 0 || quantityPerSqm === 0

	const preferredUnits = props.preferredUnits || notEnoughDataToHandleSqm

	if (!preferredUnits) {
		quantityPerSqm = quantityPerLayer / palletLayerSqm // quantityPerSqm v atributech se někde na starém webu ignoroval a kvůli rounding error dává jiné výsledky
	}

	const inputRef = React.useRef<HTMLTextAreaElement & HTMLInputElement>(null)

	const mergedValue = (() => {
		const forcedUnrounded =
			forcedQuantitySquareMeters.value * (preferredUnits ? quantityPerSqm : 1)
		const forcedRounded = roundProductQuantity(
			forcedUnrounded,
			preferredUnits,
			quantityPerSqm,
			quantityPerLayer,
		)
		const input =
			value.counter >= forcedQuantitySquareMeters.counter
				? value.value
				: forcedRounded.string
		const roundedInput = roundProductQuantity(
			Number(value.value),
			preferredUnits,
			quantityPerSqm,
			quantityPerLayer,
		)

		const numberValue =
			value.counter >= forcedQuantitySquareMeters.counter
				? roundedInput.number
				: forcedRounded.number

		const computedOther = computeProductCount(
			numberValue,
			preferredUnits,
			quantityPerSqm,
		)
		const computedPrimary = preferredUnits ? numberValue : computedOther

		return {
			input,
			roundedInput: roundedInput.string,
			computedOther,
			computedPrimary,
		}
	})()

	const commonInputProps: InputProps & {
		ref: typeof inputRef
	} = {
		micro,
		onChange: (
			event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
		) => {
			if (setValue) {
				setValue(event.target.value)
			}
		},
		onBlur: () => {
			if (setValue) {
				setValue(mergedValue.roundedInput)
			}
			if (onBlur) {
				onBlur(mergedValue.computedPrimary)
			}
		},
		value: mergedValue.input,
		type: 'number',
		min: '0',
		onKeyDown: (event: React.KeyboardEvent) => {
			if (event.key === 'Enter') {
				event.preventDefault()
				inputRef.current?.blur()
			}
		},
		ref: inputRef,
	}

	const [lastValue, setLastValue] = React.useState<string | undefined>();
	React.useEffect(() => {
		if(setValue && inputRef.current?.value != lastValue)
		{
			setLastValue(inputRef.current?.value);
			setTimeout(() => {
				setValue(inputRef.current?.value ?? '')
			}, 50);
		}
	}, [forcedQuantitySquareMeters]);

	return (
		<div className={cn(
			'quantityInput',
			highlight && 'is-highlight',
		)}>
			<div className="quantityInput-units">
				{preferredUnits ? (
					<div className={cn(
						'quantityInput-input',
						highlight && 'is-highlight',
					)}>
						<Input label="ks" step="1" {...commonInputProps} />
					</div>
				) : (
					<span className="quantityInput-computed">
						{Math.round(mergedValue.computedOther)} ks
					</span>
				)}
			</div>
			<div className="quantityInput-squareMeters">
				{!notEnoughDataToHandleSqm && (
					<>
						{preferredUnits ? (
							<span className="quantityInput-computed">
								{mergedValue.computedOther} m²
							</span>
						) : (
							<div className={cn(
								'quantityInput-input',
								highlight && 'is-highlight',
							)}>
								<Input label="m²" step="0.01" {...commonInputProps} />
							</div>
						)}
					</>
				)}
			</div>
		</div>
	)
}
