import cn from 'classnames'
import React from 'react'
import { usePopper } from 'react-popper'
import { useLocalization } from '../src/hooks/useLocaleFormatter'
import { useOnAnyClick } from '../src/hooks/useOnAnyClick'
import { useOnEscapeKey } from '../src/hooks/useOnEscapeKey'
import { ProductVariantFragment } from '../src/model/fragments'
import { getLocale } from '../src/model/locale'
import { multiplyPrices } from '../src/utils/formatPrice'
import { inflect } from '../src/utils/inflect'
import { isDefined } from '../src/utils/isDefined'
import { useIsVatPreferred } from '../src/utils/useIsVatPreferred'
import { ProductVariant } from './ProductVariant'

export interface ProductVariantPickerProps {
	items: ProductVariantFragment[],
	forceBorder?: boolean
	selectedId?: null | string
	highlight?: boolean
	readonly?: boolean
	priceMultiplier?: number,
	onChange?: (id: string) => void
}

export const ProductVariantPicker: React.FunctionComponent<ProductVariantPickerProps> =
	({
		items,
		forceBorder = false,
		selectedId = null,
		readonly = false,
		highlight = false,
		priceMultiplier = 1,
		onChange,
	}) => {
		const [isOpen, setIsOpen] = React.useState(false)
		const close = React.useCallback(() => setIsOpen(false), [setIsOpen])
		useOnEscapeKey(close, isOpen)
		useOnAnyClick(close, isOpen)
		const localization = useLocalization()
		const [isVatPreferred] = useIsVatPreferred()

		const [referenceElement, setReferenceElement] =
			React.useState<HTMLButtonElement | null>(null)
		const [popperElement, setPopperElement] =
			React.useState<HTMLElement | null>(null)
		const { styles, attributes } = usePopper(referenceElement, popperElement, {
			strategy: 'fixed',
		})

		const moreOptionsName = inflect(
			items.length,
			'varianta',
			'varianty',
			'variant',
		)

		const getTitle = React.useCallback((variant : ProductVariantFragment) => {
			return getLocale(variant.color?.locales, localization.possibleLocales)?.name ?? getLocale(variant.surface?.locales, localization.possibleLocales)?.name;
		}, [localization]);

		const getDescription = React.useCallback((variant : ProductVariantFragment) => {
			return variant.color ? getLocale(variant.surface?.locales, localization.possibleLocales)?.name : '';
		}, [localization]);

		const allPossibleNames = React.useMemo(
			() => [...items.map((it) => getTitle(it)).filter(isDefined), moreOptionsName],
			[items, moreOptionsName],
		)
		const allPossibleDescriptions = React.useMemo(
			() => items.map((it) => getDescription(it)).filter(isDefined),
			[items],
		)

		if (items.length === 0) {
			return (
				<div className="productVariantPicker-none">
					<ProductVariant name="Bez variant" />
				</div>
			)
		}

		const selected =
			(selectedId !== null && items.find((item) => item.id === selectedId)) ||
			null

		if (selected && (items.length === 1 || readonly)) {
			return (
				<div className="productVariantPicker-single">
					<ProductVariant
						name={(selected ? getTitle(selected) : null) || 'Bez názvu'}
						description={getDescription(selected)}
						image={selected.sampler?.image}
					/>
				</div>
			)
		}
	
		return (
			<div
				className={cn(
					'productVariantPicker',
					isOpen && 'is-open',
					forceBorder && 'view-forceBorder',
					highlight && 'is-highlight',
				)}
			>
				<button
					type="button"
					className="productVariantPicker-selected"
					onClick={() => setIsOpen(!isOpen)}
					ref={setReferenceElement}
				>
					{selected ? (
						<ProductVariant
							name={getTitle(selected)}
							description={getDescription(selected)}
							image={selected.sampler?.image}
							key={selectedId}
							allPossibleNames={allPossibleNames}
							allPossibleDescriptions={allPossibleDescriptions}
						/>
					) : (
						<ProductVariant
							name={moreOptionsName}
							key={selectedId}
							allPossibleNames={allPossibleNames}
							allPossibleDescriptions={allPossibleDescriptions}
						/>
					)}
				</button>
				<div
					ref={setPopperElement}
					style={{ ...styles.popper, pointerEvents: 'none', zIndex: 1 }}
					{...attributes.popper}
				>
					<div className="productVariantPicker-options">
						<div className="productVariantPicker-options-in">
							{items.map((item) => 
							{
								return (
									<button
										type="button"
										className="productVariantPicker-option"
										key={item.id}
										onClick={() => {
											if (onChange) {
												onChange(item.id!)
											}
											setIsOpen(false)
										}}
									>
										<ProductVariant
											name={getTitle(item)}
											description={getDescription(item)}
											prices={localization.formatPricesArray(localization.extractPrices(multiplyPrices(item.prices, priceMultiplier)), 1, isVatPreferred)}
											image={item.sampler?.image}
										/>
									</button>
								)}
							)}
						</div>
					</div>
				</div>
			</div>
		)
	}
