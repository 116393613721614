import cn from 'classnames'
import React from 'react'
export interface CategoryTagProps {
	text: string
	disableGutters?: boolean
	highlight?: boolean
}

export const CategoryTag: React.FunctionComponent<CategoryTagProps> = ({
	text,
	disableGutters = false,
	highlight = false,
}) => (
	<div
		className={cn(
			'categoryTag',
			disableGutters && 'view-disableGutters',
			highlight && 'view-highlight',
		)}
	>
		<span className="categoryTag-in">{text}</span>
	</div>
)

export type CategoryTag = React.FunctionComponent<CategoryTagProps>
