import React from 'react'
import { useStorageBackedState } from 'use-storage-backed-state'
import { initialCounter, useGetNextCounter } from '../hooks/useGetNextCounter'

export const useForcedQuantity = () => {
	const getNextCounter = useGetNextCounter()
	const [localQuantity, setLocalQuantity] = useStorageBackedState(
		{
			value: 0,
			counter: initialCounter,
		},
		'forced-quantity',
		'sessionStorage' in globalThis ? sessionStorage : undefined,
	)

	const setQuantity = React.useCallback(
		(newQuantity: number) => {
			setLocalQuantity({ value: newQuantity, counter: getNextCounter() })
		},
		[setLocalQuantity, getNextCounter],
	)

	return [localQuantity, setQuantity] as const
}
