import React from 'react'

const SingleSelectedColorContext = React.createContext<{
	setColor: (name: string | null) => void
	color: string | null
}>({
	setColor: () => {},
	color: null,
})

export const useSingleSelectedColor = () =>
	React.useContext(SingleSelectedColorContext).color
export const useSetSingleSelectedColor = () =>
	React.useContext(SingleSelectedColorContext).setColor

export interface SingleSelectedColorProps {}

export const SingleSelectedColor: React.FunctionComponent<SingleSelectedColorProps> =
	({ children }) => {
		const [color, setColor] = React.useState<string | null>(null)
		return (
			<SingleSelectedColorContext.Provider
				value={{
					color,
					setColor,
				}}
				key={color}
			>
				{children}
			</SingleSelectedColorContext.Provider>
		)
	}
