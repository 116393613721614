import classNames from 'classnames'
import React from 'react'
import { Align } from '../src/model/fragments'

export type BarelyVisibleTitleProps = 
{
	line?: boolean
	align?: Align
}

export const BarelyVisibleTitle: React.FunctionComponent<BarelyVisibleTitleProps> = ({ align, line, children }) => (
	<h1 className={classNames('barelyVisibleTitle', align ? ('barelyVisibleTitle-' + align) : '', line ? 'barelyVisibleTitle-line' : '')}>{children}</h1>
)
