import React from 'react'
import { useRecoilValue } from 'recoil'
import { useTranslate } from '../src/hooks/useTranslate'
import { ProductUnit } from '../src/model/fragments'
import { shoppingListState } from '../src/store/shoppingListState'
import { useForcedQuantity } from '../src/utils/useForcedQuantity'
import { Band } from './Band'
import { ProductListRow, ProductListRowProps } from './ProductListRow'
import { QuantityHeader } from './QuantityHeader'
import { VatHeader } from './VatHeader'

export interface ProductListProps {
	items: ProductListRowProps['product'][]
	isShoppingList?: boolean
	isCompositionList?: boolean
	note?: string
	highlightUnselectedVariantPickers?: boolean
	hasControlledQuantity? : boolean
}

export const ProductList: React.FunctionComponent<ProductListProps> = ({
	items,
	isShoppingList = false,
	isCompositionList = false,
	note,
	highlightUnselectedVariantPickers = false,
	hasControlledQuantity = false
}) => {
	const [isVatOpen, setIsVatOpen] = React.useState(false)
	const [isQuantityOpen, setIsQuantityOpen] = React.useState(false)
	const [forcedQuantity, setForcedQuantity] = useForcedQuantity()
	const translate = useTranslate()

	const disableHeaderQuantityInput = React.useMemo(
		() =>
			items.every(
				(item) =>
					item.attributes.unit !== ProductUnit.sqm &&
					item.variants.every(
						(variant) => (variant.attributes.quantityPerSqm || 0) === 0,
					),
			),
		[items],
	)

	if(isShoppingList)
	{
		const shoppingList = useRecoilValue(shoppingListState);
		items = items.filter(it => ![NaN, undefined, null].includes(shoppingList.variants[it.shoppingListVariantId!]));
	}

	return (
		<Band>
			<div className="productList">
				<table className="productList-table">
					<thead className="productList-header">
						<tr>
							<th className="productList-header-name">
								{translate('product-name-header')}
							</th>
							<th>
								{translate('product-dimensions-header')}
								<br />
								{translate('product-dimensions-note')}
							</th>
							<th className="productList-header-variant">
								{translate('product-variant-header')}
							</th>
							{isShoppingList && (
								<th
									onMouseEnter={() => setIsVatOpen(true)}
									onMouseLeave={() => setIsVatOpen(false)}
									className="productList-header-price"
								>
									<VatHeader isOpen={isVatOpen} />
								</th>
							)}
							{!isShoppingList && (
								<th
									onMouseEnter={() => setIsVatOpen(true)}
									onMouseLeave={() => setIsVatOpen(false)}
									className="productList-header-price"
								>
									<VatHeader isOpen={isVatOpen} />
								</th>
							)}
							{isCompositionList || hasControlledQuantity ? (
								<th>{translate('product-quantity-header')}</th>
							) : (
								<th
									onMouseEnter={() => setIsQuantityOpen(true)}
									onMouseLeave={() => setIsQuantityOpen(false)}
								>
									<QuantityHeader
										isOpen={isQuantityOpen}
										isShoppingList={isShoppingList}
										onChange={setForcedQuantity}
										disableQuantityInput={disableHeaderQuantityInput}
									/>
								</th>
							)}
							{isShoppingList && (
								<th className="productList-header-price">
									{translate('product-total-price-noVat-header')}
								</th>
							)}
							{isShoppingList && (
								<th className="productList-header-price">
									<b>{translate('product-total-price-vat-header')}</b>
								</th>
							)}
							{isShoppingList && <th></th>}
							{!isShoppingList && !isCompositionList && (
								<th>{translate('product-add-header')}</th>
							)}
						</tr>
					</thead>
					<tbody className="productList-items">
						{items.length === 0 ? (
							<tr>
								<td
									className="productList-note"
									colSpan={isShoppingList ? 8 : 6}
								>
									{note || translate('product-list-noMatch')}
								</td>
							</tr>
						) : (
							items.map((item, index) => (
								<ProductListRow
									index={index}
									product={item}
									key={`${item.id}_${item.shoppingListVariantId}`}
									forcedQuantity={forcedQuantity}
									onQuantityMouseEnter={() => setIsQuantityOpen(true)}
									onQuantityMouseLeave={() => setIsQuantityOpen(false)}
									onPriceMouseEnter={() => setIsVatOpen(true)}
									onPriceMouseLeave={() => setIsVatOpen(false)}
									hasControlledQuantity={hasControlledQuantity}
									isShoppingList={isShoppingList}
									isCompositionList={isCompositionList}
									highlightUnselectedVariantPicker={
										highlightUnselectedVariantPickers
									}
								/>
							))
						)}
					</tbody>
				</table>
			</div>
		</Band>
	)
}
