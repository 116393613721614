import Link from 'next/link'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { initialCounter } from '../src/hooks/useGetNextCounter'
import { useLocalization } from '../src/hooks/useLocaleFormatter'
import { Currency } from '../src/model/currency'
import { ProductFragment, ProductUnit } from '../src/model/fragments'
import { getLocale } from '../src/model/locale'
import { computeProductCount } from '../src/model/product'
import { getProductLink } from '../src/model/urls'
import {
	shoppingListState,
	useAdjustVariantInShoppingList,
} from '../src/store/shoppingListState'
import { formatDimensions } from '../src/utils/formatDimensions'
import { useCheapestVariant } from '../src/utils/useCheapestVariant'
import { useIsVatPreferred } from '../src/utils/useIsVatPreferred'
import { useQuantityInput } from '../src/utils/useQuantityInput'
import { useSelectedVariantId } from '../src/utils/useSelectedVariantId'
import { AddToShoppingListButton } from './AddToShoppingListButton'
import { CategoryTag } from './CategoryTag'
import { ConditionalWrap } from './ConditionalWrap'
import { Picture } from './Picture'
import { ProductVariantPicker } from './ProductVariantPicker'
import { QuantityInput } from './QuantityInput'
import { RemoveFromShoppingListButton as RemoveFromShoppingListButton } from './RemoveFromShoppingListButton'
import { useSingleSelectedColor } from './SingleSelectedColor'

export interface ProductListRowProps {
	product: ProductFragment & {
		controlledQuantity?: number
		controlledVariantId?: null | string
		shoppingListVariantId?: null | string
		onControlledVariantIdChange?: (id: null | string) => void
	},	
	forcedQuantity?: {
		value: number
		counter: number
	}
	isShoppingList?: boolean
	isCompositionList?: boolean
	onQuantityMouseEnter?: () => void
	onQuantityMouseLeave?: () => void
	onPriceMouseEnter?: () => void
	onPriceMouseLeave?: () => void
	highlightUnselectedVariantPicker?: boolean
	hasControlledQuantity?: boolean
	index? : number
}

export const ProductListRow: React.FunctionComponent<ProductListRowProps> = ({
	product,
	forcedQuantity = {
		value: 0,
		counter: initialCounter,
	},
	isShoppingList = false,
	isCompositionList = false,
	onQuantityMouseEnter,
	onQuantityMouseLeave,
	onPriceMouseEnter,
	onPriceMouseLeave,
	highlightUnselectedVariantPicker = false,
	hasControlledQuantity = false,
	index = undefined
}) => {
	const localization = useLocalization()
	const data = getLocale(product.locales, localization.possibleLocales);
	if(!data)
	{
		return <></>;
	}

	const shoppingList = useRecoilValue(shoppingListState)
	const adjustVariantInShoppingList = useAdjustVariantInShoppingList()

	const [quantityInput, setQuantityInput] = useQuantityInput(product.id)

	const [initialVariantId] = React.useState(
		product.variants.length === 1 ? product.variants[0].id : null
	)
	const [variantId, setVariantId] = useSelectedVariantId(
		product.id,
		initialVariantId,
	)

	const variant = React.useMemo(() => {
		if (product.controlledVariantId || product.controlledVariantId === null) 
		{
			const vv = product.variants.find(
				(it) => it.id === product.controlledVariantId
			);

			if(vv)
			{
				return vv;
			}
		}

		if (product.variants.length === 1) {
			return product.variants[0]
		}
		else if(!isCompositionList)
		{
			return product.variants.find((it) => it.id === variantId)
		}
		
		return null
	}, [product, variantId]);

	const singleColorFiltered = useSingleSelectedColor()
	const [lastSingleColorFiltered, setLastSingleColorFiltered] = React.useState<
		string | null
	>(null)
	React.useEffect(() => {
		if (singleColorFiltered !== lastSingleColorFiltered) {
			setLastSingleColorFiltered(singleColorFiltered)
			const id =
				product.variants.find(
					(variant) => variant.color.id === singleColorFiltered,
				)?.id || null
			setVariantId(id)
		}
	}, [
		product,
		setVariantId,
		singleColorFiltered,
		initialVariantId,
		lastSingleColorFiltered,
	])

	React.useEffect(() => {
		if (
			isShoppingList &&
			product.shoppingListVariantId &&
			quantityInput.value === ''
		) {
			const quantity = shoppingList.variants[product.shoppingListVariantId]
			if(quantity)
			{
				setQuantityInput(quantity.toString());
			}
		}
	}, [
		isShoppingList,
		product.shoppingListVariantId,
		shoppingList.variants
	])

	const cheapestVariant = useCheapestVariant(product.variants)
	const quantity = Number(quantityInput.value)
	const [isVatPreferred] = useIsVatPreferred()

	const cheapestPrice = variant ? localization.extractPrices(variant.prices).reduce((pr, acc) => {
		return pr.cents < acc.cents ? pr : acc;
	}, variant.prices[0]) : undefined;

	const dimensionsImageAspectRatio =
		(product.listingImage?.intrinsicWidth || 1) / (product.listingImage?.intrinsicWidth || 1)

	const [isButtonHover, setIsButtonHover] = React.useState<boolean>(false);
	const highlightVariant = (isButtonHover || highlightUnselectedVariantPicker) && !variant;
	const highlightQuantity = isButtonHover && !quantityInput.value;

	const quantityPerSqm = (variant || cheapestVariant).attributes!.quantityPerSqm;
	const compositionQuantity = computeProductCount(
		product.controlledQuantity || 0,
		false,
		quantityPerSqm || 0,
	);
	const compositionSquareMeters = quantityPerSqm ? (compositionQuantity /  quantityPerSqm) : 0;

	return (
		<tr className="productListRow">
			<td className="productListRow-name">
				<div className="productListRow-name-in">
					{ isCompositionList && index !== undefined &&
						<div className="productListRow-composition-number"><span>{ index + 1 }</span></div>
					}
					{ (product.link) && (
						<Link
							{...getProductLink(localization.locale, product.id, (product.link.url)!)}
						>
							<a className="productListRow-name-link">
								{data.name}
								{product.newProduct && (
									<>
										<br/>
										<span className="productListRow-name-new">
											<CategoryTag text="Novinka" disableGutters highlight />
										</span>
									</>
								)}
							</a>
						</Link>
					)}
					<div className="productListRow-name-alternative">
						{data.altName}
					</div>
				</div>
			</td>
			<td>
				<div className="productListRow-dimensions">
					{product.listingImage && (
						<div
							className="productListRow-dimensions-picture"
							style={{
								['--productListRow-aspect-ratio' as any]:
									dimensionsImageAspectRatio,
							}}
						>
							<Picture
								image={product.listingImage}
								width={56}
								height={56}
								alt={data.name}
							/>
						</div>
					)}
					{
						formatDimensions(
							product.attributes?.length,
							product.attributes?.width,
							product.attributes?.height,
						)
					}
				</div>
			</td>
			<td className='productListRow-variant'>
				<ProductVariantPicker
					items={isShoppingList ? [variant!] : product.variants}
					selectedId={variant && variant.id}
					onChange={product.onControlledVariantIdChange || setVariantId}
					highlight={highlightVariant}
				/>
			</td>
			<td
				className="productListRow-price"
				onMouseEnter={onPriceMouseEnter}
				onMouseLeave={onPriceMouseLeave}
			>
				{ localization.extractPrices((variant || cheapestVariant)?.prices).map(variantPrice => 
					<>
						<ConditionalWrap
						condition={!isShoppingList}
						wrap={(children) => <b>{children}</b>}
						>
							<>
								{(variant || product.variants.length > 0) &&
									localization.formatPrice(
										{...variantPrice, unit: product.attributes.unit},
										1,
										isVatPreferred,
									)}
							</>
						</ConditionalWrap> 
						<br/>
					</>
				)}
				
			</td>
			{isCompositionList || hasControlledQuantity ? (

					isCompositionList ? (
						<td className="productListRow-quantity" style={{ textAlign: "right" }}>
						{Math.ceil(compositionQuantity)} ks / <span className='quantityInput-computed'>{product.controlledQuantity ? product.controlledQuantity.toFixed(2) : '0'}  m²</span>
					</td>
					) : (
						<td className="productListRow-quantity" style={{ textAlign: "right" }}>
						{product.controlledQuantity} ks
					</td>
					)
				
			) : (
				<td
					className="productListRow-quantity"
					onMouseEnter={onQuantityMouseEnter}
					onMouseLeave={onQuantityMouseLeave}
				>
					<div className="productListRow-quantity-in">
						<QuantityInput
							preferredUnits={product.attributes.unit !== ProductUnit.sqm}
							quantityPerSqm={
								(variant || cheapestVariant)?.attributes.quantityPerSqm || 0
							}
							quantityPerLayer={
								(variant || cheapestVariant)?.attributes.palletLayerQuantity ||
								0
							}
							palletLayerSqm={
								(variant || cheapestVariant)?.attributes.palletLayerSqm || 0
							}
							micro
							highlight={highlightQuantity}
							value={quantityInput}
							forcedQuantitySquareMeters={forcedQuantity}
							setValue={setQuantityInput}
							onBlur={(newValue) => {
								if (isShoppingList && product.shoppingListVariantId && variant) {

									const oldValue =
										shoppingList.variants[product.shoppingListVariantId]

									let updatedValue = newValue;
									if(product.attributes.unit == ProductUnit.sqm)
									{
										const quantityPerSqm = parseFloat((variant.attributes.quantityPerSqm.toString() ?? (variant.attributes.palletLayerQuantity / variant.attributes.palletLayerSqm)).toString());
										updatedValue = updatedValue / quantityPerSqm
									}

									if (oldValue !== updatedValue) {
										adjustVariantInShoppingList(
											product.shoppingListVariantId,
											updatedValue - oldValue,
										)
									}
								}
							}}
						/>
					</div>
				</td>
			)}
			{isShoppingList && (
				<td className="productListRow-price">
					{(variant || product.variants.length > 0) &&
						 localization.extractPrices((variant || cheapestVariant).prices).map(variantPrice => 
							<>
								{localization.formatPrice(
									variantPrice,
									quantity,
									false,
								)}
								<br/>
							</>
						 )
					}
				</td>
			)}
			{isShoppingList && (
				<td className="productListRow-price">
					
					<b>
						{(variant || product.variants.length > 0) &&
							localization.extractPrices((variant || cheapestVariant).prices).map(variantPrice => 
								<>
									{localization.formatPrice(
										variantPrice,
										quantity,
										true,
									)}
									<br/>
								</>	
						)}
					</b>
				</td>
			)}
			{!isCompositionList && (
				<td className="productListRow-action">
					{isShoppingList ? (
						variant && <RemoveFromShoppingListButton variantId={variant.id} />
					) : (
						<AddToShoppingListButton
							variantId={(variant && variant.id) ?? undefined}
							variantPrice={cheapestPrice}
							quantityInput={hasControlledQuantity ? (product.controlledQuantity as unknown as string)! : quantityInput.value}
							onClick={() => setQuantityInput('')}
							onMouseEnter={() => setIsButtonHover(true)}
							onMouseLeave={() => setIsButtonHover(false)}
						/>
					)}

					<div className='productListRow-action-mobile'>
						{
							(variant || cheapestVariant)?.prices.filter(pr => pr.currency === Currency.CZK || pr.region).map(variantPrice => 
							<>
								{(!variant && cheapestVariant) ? 'od ' : ''}
								<strong>
									{localization.formatPrice(
										{...variantPrice, unit: undefined},
										hasControlledQuantity ? product.controlledQuantity! : quantity,
										true,
									)}
								</strong>
								<br/>
							</>)	
						}
					</div>
				</td>
			)}
		</tr>
	)
}
