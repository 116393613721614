import React from 'react'
import { usePopper } from 'react-popper'
import { useTranslate } from '../src/hooks/useTranslate'
import SvgChevron from './icons/SvgChevron'
import { Input } from './Input'
import { Popup } from './Popup'

export interface QuantityHeaderProps {
	isOpen: boolean
	isShoppingList?: boolean
	disableQuantityInput?: boolean
	onChange: (value: number) => void
}

export const QuantityHeader: React.FunctionComponent<QuantityHeaderProps> = ({
	isOpen,
	isShoppingList = false,
	disableQuantityInput = false,
	onChange,
}) => {
	const translate = useTranslate()
	const [referenceElement, setReferenceElement] =
		React.useState<HTMLElement | null>(null)

	const [input, setInput] = React.useState('')

	return (
		<div className="quantityHeader" ref={setReferenceElement}>
			<div className="quantityHeader-label">
				{translate('product-quantity-units-header')}
			</div>
			<div
				style={{ ...{pointerEvents: 'none', zIndex: 1} }}
			>
				{!isShoppingList && !disableQuantityInput && (
					<Popup isOpen={isOpen}>
						<form
							onSubmit={(event) => {
								event.preventDefault()
								onChange(Number(input))
								setInput('')
							}}
						>
							<label className="quantityHeader-popup">
								<div className="quantityHeader-popup-label">
									{translate('product-quantity-header-text')}
								</div>
								<Input
									value={input}
									micro
									condense
									submitStyle="bland"
									submitIcon={
										<SvgChevron className="quantityHeader-popup-chevron" />
									}
									type="number"
									onChange={(event) => setInput(event.target.value)}
								/>
							</label>
						</form>
					</Popup>
				)}
			</div>
		</div>
	)
}
