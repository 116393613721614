import React from 'react'
import { useRecoilValue } from 'recoil'
import {
	shoppingListState,
	useAdjustVariantInShoppingList,
} from '../src/store/shoppingListState'
import { Cross, CrossView } from './Cross'

export interface RemoveFromShoppingListButtonProps {
	variantId: string
}

export const RemoveFromShoppingListButton: React.FunctionComponent<RemoveFromShoppingListButtonProps> =
	({ variantId }) => {
		const adjustVariantInShoppingList = useAdjustVariantInShoppingList()
		const shoppingList = useRecoilValue(shoppingListState)

		return (
			<button
				className="removeFromShoppingListButton"
				type="button"
				onClick={() =>
					adjustVariantInShoppingList(
						variantId,
						-shoppingList.variants[variantId],
					)
				}
				aria-label="Odebrat ze seznamu"
			>
				<Cross view={CrossView.removeButton} />
			</button>
		)
	}
