export function inflect(
	count: number,
	one: string,
	twoToFour: string,
	other: string,
) {
	if (count === 1) {
		return `${count} ${one}`
	}
	if (2 <= count && count <= 4) {
		return `${count} ${twoToFour}`
	}
	return `${count} ${other}`
}
