import cn from 'classnames'
import React from 'react'

export enum CrossView {
	default = 'default',
	popup = 'popup',
	removeButton = 'removeButton',
}

interface CrossProps {
	view?: CrossView
}

export const Cross: React.FunctionComponent<CrossProps> = (props) => (
	<span className={cn('cross', `view-${props.view || CrossView.default}`)} />
)
