export const initialCounter = 0

const STORAGE_KEY = 'counter'

let lastCount =
	('sessionStorage' in globalThis &&
		parseInt(sessionStorage.getItem(STORAGE_KEY) || '')) ||
	initialCounter

export const useGetNextCounter = () => {
	const getNextCounter = () => {
		lastCount++
		sessionStorage.setItem(STORAGE_KEY, lastCount.toString())
		return lastCount
	}
	return getNextCounter
}
