import cn from 'classnames'
import React from 'react'

export interface PopupProps {
	isOpen: boolean
}

export const Popup: React.FunctionComponent<PopupProps> = ({
	isOpen,
	children,
}) => {
	return <div className={cn('popup', isOpen && 'is-open')}>{children}</div>
}
