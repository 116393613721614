import React from 'react'
import { usePossibleLocales } from '../src/hooks/usePossibleLocales'
import { VideoFragment } from '../src/model/fragments'
import { getLocale } from '../src/model/locale'

export interface VideoArticleProps {
	video: VideoFragment
}

export const VideoArticle: React.FunctionComponent<VideoArticleProps> = ({
	video,
}) => {
	const possibleLocales = usePossibleLocales();
	const data = video ? getLocale(video.locales, possibleLocales) : null;
	if(!data)
	{
		return <></>;
	}

	return (
		<div className="videoArticle">
			<iframe
				className="videoArticle-in"
				width="560"
				height="315"
				src={`https://www.youtube.com/embed/${data.id}`}
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</div>
	)
}
