import React from 'react'
import { usePopper } from 'react-popper'
import { useTranslate } from '../src/hooks/useTranslate'
import { useIsVatPreferred } from '../src/utils/useIsVatPreferred'
import { Checkbox } from './Checkbox'
import { Popup } from './Popup'

export interface VatHeaderProps {
	isOpen: boolean
}

export const VatHeader: React.FunctionComponent<VatHeaderProps> = ({
	isOpen,
}) => {
	const translate = useTranslate()
	const [referenceElement, setReferenceElement] =
		React.useState<HTMLElement | null>(null)
	const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
		null,
	)
	const { styles, attributes } = usePopper(referenceElement, popperElement, {
		strategy: 'fixed',
		placement: 'top',
	})

	const [isVatPreferred, setIsVatPreferred] = useIsVatPreferred()

	return (
		<div className="vatHeader" ref={setReferenceElement}>
			<div className="vatHeader-label">
				{isVatPreferred ? 'Cena s DPH' : 'Cena bez DPH'}
			</div>
			<div
				ref={setPopperElement}
				style={{ ...styles.popper, pointerEvents: 'none', zIndex: 1 }}
				{...attributes.popper}
			>
				<Popup isOpen={isOpen}>
					<div className="vatHeader-popup-label">
						{translate('vatHeader-popup-label')}
					</div>
					<Checkbox
						label={translate('vatHeader-popup-apply')}
						control={{
							onChange: (event) =>
								setIsVatPreferred(!event.currentTarget.checked),
							checked: !isVatPreferred,
						}}
					/>
				</Popup>
			</div>
		</div>
	)
}
