import { useStorageBackedState } from 'use-storage-backed-state'

export function useSelectedVariantId(
	productId: string,
	defaultValue: string | null
) {
	return useStorageBackedState(
		defaultValue,
		`product-selected-variant-${productId}`,
		'sessionStorage' in globalThis ? sessionStorage : undefined,
	)
}
